/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { EpisodeConsumer } from "../components/context"
import SEO from "../components/seo"
import Header from "../components/header"
import Aside from "../components/aside"
import { SkipNavContent } from "@reach/skip-nav"

function EpisodeTemplate({ data: { markdownRemark: episode } }) {
  const image = episode && episode.frontmatter.image
  const markdown = episode && episode
  return (
    <EpisodeConsumer>
      {context => (
        <div sx={{ flex: "1 0 0" }}>
          <SEO
            title={episode.frontmatter.title && episode.frontmatter.title}
            image={image && image.childImageSharp.original.src}
            description={episode.description && episode.description}
          />
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              flex: "1 0 0",
            }}
          >
            <SkipNavContent sx={{ maxWidth: ["100%", 710], flex: "1 0 0" }}>
              <Header context={context} episode={episode} image={image} />
              <article>
                <p>
                  {episode.frontmatter.description &&
                    episode.frontmatter.description}
                </p>
                {markdown && (
                  <div dangerouslySetInnerHTML={{ __html: markdown.html }} />
                )}
              </article>
            </SkipNavContent>
            <Aside markdown={markdown} />
          </div>
        </div>
      )}
    </EpisodeConsumer>
  )
}

export default EpisodeTemplate

export const episodeQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        id
        number
        title
        summary
        number
        resources
        guestName
        enclosure_url
        guestSummary
        # guestPhoto {
        #   childImageSharp {
        #     fluid(maxWidth: 200) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
